import { AchievementGetRankingsResponse, AchievementsGetResponse, AdminPanelBrute, BruteGetInventoryResponse, BruteReportsListResponse, BrutesCreateResponse, BrutesExistsResponse, BrutesGetClanIdAsMasterResponse, BrutesGetDestinyResponse, BrutesGetFightsLeftResponse, BrutesGetForRankResponse, BrutesGetForVersusResponse, BrutesGetLevelUpChoicesResponse, BrutesGetOpponentsResponse, BrutesGetRankingResponse, BruteUpdateEventRoundWatchedResponse, ClanChallengeBossResponse, ClanCreateResponse, ClanGetForAdminResponse, ClanGetResponse, ClanGetThreadResponse, ClanGetThreadsResponse, ClanListResponse, ClanSort, ClanWarGetAvailableFightersResponse, ClanWarGetHistoryResponse, ClanWarGetResponse, ClanWarGetUsedFightersResponse, ConfigsDecryptRequest, ConfigsDecryptResponse, ConfigsListResponse, ConfigsSetRequest, ConfigsSetResponse, EventGetResponse, EventGetRoundResponse, EventListCurrentResponse, EventListResponse, FightCreateResponse, FightGetResponse, HookBrute, LogGetForUserFeedResponse, LogListResponse, NotificationListResponse, ServerReadyResponse, TournamentHistoryResponse, TournamentsGetDailyResponse, TournamentsGetGlobalResponse, TournamentsUpdateStepWatchedResponse, TournementsUpdateGlobalRoundWatchedResponse, UserBannedListResponse, UserGetAdminResponse, UserGetNextModifiersResponse, UserGetProfileResponse, UserLogsListRequest, UserLogsListResponse, UserMultipleAccountsListResponse, UsersAdminUpdateRequest, UsersAuthenticateResponse, UserUpdateSettingsRequest } from '@labrute/core';
import { Brute, BruteReportReason, BruteReportStatus, Clan, DestinyChoiceSide, FightModifier, Gender, InventoryItemType, Lang, PetName, Prisma, SkillName, WeaponName } from '@labrute/prisma';
import Fetch from './Fetch';

const Server = {
  isReady: () => Fetch<ServerReadyResponse>('/api/is-ready'),
  csrf: () => Fetch<{ csrfToken: string }>('/api/csrf'),
  User: {
    authenticate: (login: string, token: string) => Fetch<UsersAuthenticateResponse>('/api/user/authenticate', {
      login,
      token
    }, 'POST'),
    getForAdmin: (id: string) => Fetch<UserGetAdminResponse>(`/api/user/${id}/admin`),
    runDailyJob: () => Fetch<never>('/api/run-daily-job', {}, 'PATCH'),
    changeLanguage: (lang: Lang) => Fetch<never>('/api/user/change-language', { lang }, 'PUT'),
    changeFightSpeed: (fightSpeed: number) => Fetch<never>('/api/user/change-fight-speed', { fightSpeed }, 'PUT'),
    toggleBackgroundMusic: (backgroundMusic: boolean) => Fetch<never>('/api/user/toggle-background-music', { backgroundMusic }, 'PUT'),
    adminUpdate: (id: string, data: UsersAdminUpdateRequest) => Fetch<never>(`/api/user/${id}/admin-update`, data, 'PUT'),
    getProfile: (id: string) => Fetch<UserGetProfileResponse>(`/api/user/${id}/profile`),
    getDinoRpgRewards: () => Fetch<never>('/api/user/get-dinorpg-reward', {}, 'PATCH'),
    ban: (id: string, reason: string) => Fetch<never>(`/api/user/${id}/ban`, { reason }, 'PATCH'),
    unban: (id: string) => Fetch<never>(`/api/user/${id}/unban`, {}, 'PATCH'),
    banlist: () => Fetch<UserBannedListResponse>('/api/user/banlist'),
    multipleAccounts: () => Fetch<UserMultipleAccountsListResponse>('/api/user/multiple-accounts'),
    getNextModifiers: () => Fetch<UserGetNextModifiersResponse>('/api/user/next-modifiers'),
    setNextModifiers: (modifiers: FightModifier[]) => Fetch<never>('/api/user/next-modifiers', { modifiers }, 'PUT'),
    toggleFollow: (bruteId: string) => Fetch<never>(`/api/user/toggle-follow/${bruteId}`, {}, 'PATCH'),
    updateSettings: (settings: UserUpdateSettingsRequest) => Fetch<never>('/api/user/settings', settings, 'PUT'),
    disconnect: () => Fetch<never>('/api/user/disconnect', {}, 'PATCH'),
  },
  Brute: {
    getForHook: (name: string) => Fetch<HookBrute>(`/api/brute/${name}/for-hook`),
    getForAdmin: (name: string) => Fetch<AdminPanelBrute>(`/api/brute/${name}/for-admin`),
    getForVersus: (name: string) => Fetch<BrutesGetForVersusResponse>(`/api/brute/${name}/for-versus`),
    isNameAvailable: (name: string) => Fetch<boolean>(`/api/brute/${name}/available`),
    create: (
      name: string,
      user: string,
      gender: Gender,
      body: string,
      colors: string,
      master: string | null,
      eventId: string | null,
    ) => Fetch<BrutesCreateResponse>('/api/brute', {
      name,
      user,
      gender,
      body,
      colors,
      master,
      eventId,
    }, 'PUT'),
    getLevelUpChoices: (name: string) => Fetch<BrutesGetLevelUpChoicesResponse>(`/api/brute/${name}/level-up-choices`),
    levelUp: (
      name: string,
      choice: DestinyChoiceSide,
    ) => Fetch<Brute>(`/api/brute/${name}/level-up`, { choice }, 'PATCH'),
    getOpponents: (name: string, level: number) => Fetch<BrutesGetOpponentsResponse>(`/api/brute/${name}/get-opponents/${level}`),
    sacrifice: (name: string) => Fetch<{ gold: number }>(`/api/brute/${name}`, {}, 'DELETE'),
    getForRank: ({ name, rank }: { name: string, rank?: number }) => Fetch<BrutesGetForRankResponse>(`/api/brute/${name}/ranking-data${typeof rank === 'undefined' ? '' : `/${rank}`}`),
    getRanking: (name: string) => Fetch<BrutesGetRankingResponse>(`/api/brute/${name}/ranking`),
    exists: (name: string) => Fetch<BrutesExistsResponse>(`/api/brute/${name}/exists`),
    rankUp: (name: string) => Fetch<never>(`/api/brute/${name}/rank-up`, {}, 'PATCH'),
    ascend: (name: string, data: { weapon?: WeaponName, skill?: SkillName, pet?: PetName }) => Fetch<never>(`/api/brute/${name}/ascend`, { data }, 'PATCH'),
    getDestiny: (name: string) => Fetch<BrutesGetDestinyResponse>(`/api/brute/${name}/destiny`),
    getFightsLeft: (name: string) => Fetch<BrutesGetFightsLeftResponse>(`/api/brute/${name}/fights-left`),
    adminUpdate: (name: string, data: Prisma.BruteUncheckedUpdateInput) => Fetch<never>(`/api/brute/${name}/admin-update`, data, 'PUT'),
    restore: (id: string) => Fetch<never>(`/api/brute/${id}/restore`, {}, 'PUT'),
    favorite: (name: string) => Fetch<never>(`/api/brute/${name}/favorite`, {}, 'PATCH'),
    reset: (name: string) => Fetch<HookBrute>(`/api/brute/${name}/reset`, {}, 'PATCH'),
    resetVisuals: (name: string, body: string, colors: string) => Fetch<never>(`/api/brute/${name}/reset-visuals`, { body, colors }, 'PATCH'),
    changeName: (name: string, newName: string) => Fetch<never>(`/api/brute/${name}/change-name/${newName}`, {}, 'PATCH'),
    getInventory: (name: string) => Fetch<BruteGetInventoryResponse>(`/api/brute/${name}/inventory`),
    giveItem: (id: string, item: InventoryItemType) => Fetch<never>('/api/brute/item', { id, item }, 'PUT'),
    getClanIdAsMaster: (name: string) => Fetch<BrutesGetClanIdAsMasterResponse>(`/api/brute/${name}/master-clan-id`),
    updateEventRoundWatched: (name: string, fight: string) => Fetch<BruteUpdateEventRoundWatchedResponse>(`/api/brute/${name}/update-event-round-watched/${fight}`, {}, 'PUT'),
  },
  Log: {
    list: (brute: string) => Fetch<LogListResponse>(`/api/log/list/${brute}`),
    getForUserFeed: (page: number) => Fetch<LogGetForUserFeedResponse>(`/api/log/user-feed/${page}`),
  },
  Fight: {
    get: (name: string, id: string) => Fetch<FightGetResponse>(`/api/fight/${name}/${id}`),
    create: (brute1: string, brute2: string) => Fetch<FightCreateResponse>('/api/fight', { brute1, brute2 }, 'PATCH'),
    toggleFavorite: (id: string) => Fetch<never>(`/api/fight/${id}/toggle-favorite`, {}, 'PATCH'),
  },
  Tournament: {
    getDaily: ({
      name,
      date,
    }: { name: string, date: string }) => Fetch<TournamentsGetDailyResponse>(`/api/tournament/${name}/${date}`),
    registerDaily: (name: string) => Fetch<never>(`/api/tournament/${name}/register`, {}, 'PATCH'),
    updateStepWatched: (name: string) => Fetch<TournamentsUpdateStepWatchedResponse>(`/api/tournament/${name}/update-step-watched`, {}, 'PATCH'),
    updateGlobalRoundWatched: (name: string, fight: string) => Fetch<TournementsUpdateGlobalRoundWatchedResponse>(`/api/tournament/${name}/update-global-round-watched/${fight}`, {}, 'PATCH'),
    skipWatchingGlobal: (name: string) => Fetch<never>(`/api/tournament/${name}/skip-watching-global`, {}, 'PUT'),
    setDailyWatched: (name: string) => Fetch<never>(`/api/tournament/${name}/set-daily-watched`, {}, 'PUT'),
    getGlobal: ({
      name,
      date,
    }: { name: string, date: string }) => Fetch<TournamentsGetGlobalResponse>(`/api/tournament/global/${name}/${date}`),
    deleteDaily: () => Fetch<never>('/api/tournament/daily', {}, 'DELETE'),
    deleteGlobal: () => Fetch<never>('/api/tournament/global', {}, 'DELETE'),
    getHistory: (name: string) => Fetch<TournamentHistoryResponse>(`/api/tournament/${name}/history`),
    isGlobalTournamentValid: () => Fetch<{ isValid: boolean }>('/api/tournament/is-valid/global'),
  },
  Achievement: {
    getForUser: (userId: string) => Fetch<AchievementsGetResponse>('/api/achievements', { userId }, 'POST'),
    getForBrute: (name: string) => Fetch<AchievementsGetResponse>(`/api/achievements/${name}`),
    getRankings: (byUser: boolean) => Fetch<AchievementGetRankingsResponse>('/api/achievements/rankings/all', { byUser }),
  },
  BruteReport: {
    list: (status: BruteReportStatus, page: number) => Fetch<BruteReportsListResponse>(`/api/report/list/${status}/${page}`),
    send: (name: string, reason: BruteReportReason) => Fetch<never>(`/api/report/send/${name}/${reason}`, {}, 'PUT'),
    accept: (id: string) => Fetch<never>(`/api/report/${id}/accept`, {}, 'PATCH'),
    reject: (id: string) => Fetch<never>(`/api/report/${id}/reject`, {}, 'PATCH'),
    addBannedWord: (word: string) => Fetch<never>(`/api/report/banned-word/${word}`, {}, 'PUT'),
  },
  Clan: {
    list: ({ page, search, sort }: { page: number, search: string, sort: ClanSort }) => Fetch<ClanListResponse>('/api/clan/list', { page, search, sort }),
    create: (brute: string, name: string) => Fetch<ClanCreateResponse>(`/api/brute/${brute}/clan/create`, { name }, 'PUT'),
    get: (id: string) => Fetch<ClanGetResponse>(`/api/clan/${id}`),
    request: (brute: string, id: string) => Fetch<never>(`/api/brute/${brute}/clan/${id}/request`, {}, 'PUT'),
    cancelRequest: (brute: string, id: string) => Fetch<never>(`/api/brute/${brute}/clan/${id}/request`, {}, 'DELETE'),
    accept: (brute: string, id: string) => Fetch<never>(`/api/clan/${id}/accept/${brute}`, {}, 'PATCH'),
    reject: (brute: string, id: string) => Fetch<never>(`/api/clan/${id}/reject/${brute}`, {}, 'PATCH'),
    remove: (brute: string, id: string) => Fetch<never>(`/api/clan/${id}/remove/${brute}`, {}, 'PATCH'),
    setMaster: (brute: string, id: string) => Fetch<never>(`/api/clan/${id}/master/${brute}`, {}, 'PUT'),
    leave: (brute: string, id: string) => Fetch<never>(`/api/brute/${brute}/clan/${id}/leave`, {}, 'PATCH'),
    getThreads: (data: { brute: string, id: string }) => Fetch<ClanGetThreadsResponse>(`/api/brute/${data.brute}/clan/${data.id}/threads`),
    createThread: (
      brute: string,
      id: string,
      title: string,
      content: string,
    ) => Fetch<ClanGetThreadsResponse>(`/api/brute/${brute}/clan/${id}/thread/create`, { title, content }, 'PUT'),
    updateThread: (
      brute: string,
      id: string,
      title: string,
      content: string,
      threadId: string,
      postId: string,
    ) => Fetch<never>(`/api/brute/${brute}/clan/${id}/thread/${threadId}`, { title, content, postId }, 'PATCH'),
    createPost: (
      brute: string,
      id: string,
      content: string,
    ) => Fetch<never>(`/api/brute/${brute}/thread/${id}/post/create`, { content }, 'PUT'),
    lockThread: (brute: string, id: string, threadId: string) => Fetch<never>(`/api/brute/${brute}/clan/${id}/thread/${threadId}/lock`, {}, 'PATCH'),
    pinThread: (brute: string, id: string, threadId: string) => Fetch<never>(`/api/brute/${brute}/clan/${id}/thread/${threadId}/pin`, {}, 'PATCH'),
    deleteThread: (brute: string, id: string, threadId: string) => Fetch<never>(`/api/brute/${brute}/clan/${id}/thread/${threadId}`, {}, 'DELETE'),
    unpinThread: (brute: string, id: string, threadId: string) => Fetch<never>(`/api/brute/${brute}/clan/${id}/thread/${threadId}/unpin`, {}, 'PATCH'),
    getThread: (brute: string, id: string, threadId: string, page: number) => Fetch<ClanGetThreadResponse>(`/api/brute/${brute}/clan/${id}/thread/${threadId}`, { page }),
    challengeBoss: (brute: string, id: string) => Fetch<ClanChallengeBossResponse>(`/api/brute/${brute}/clan/${id}/challenge-boss`, {}, 'PUT'),
    toggleWar: (id: string) => Fetch<never>(`/api/clan/${id}/toggle-war`, {}, 'PUT'),
    getForAdmin: (id: string) => Fetch<ClanGetForAdminResponse>(`/api/clan/${id}/admin`),
    adminUpdate: (id: string, data: Clan) => Fetch<never>(`/api/clan/${id}/update`, data, 'PATCH'),
  },
  ClanWar: {
    declareFriendlyWar: (bruteId: string, clan: string, opponent: string) => Fetch<ClanWarGetResponse>('/api/clan/war/friendly', {
      brute: bruteId,
      clan,
      opponent,
    }, 'PUT'),
    get: (clan: string, war: string) => Fetch<ClanWarGetResponse>(`/api/clan/${clan}/war/${war}`),
    getHistory: (clan: string) => Fetch<ClanWarGetHistoryResponse>(`/api/clan/${clan}/war/history`),
    getAvailableFighters: (clan: string, war: string) => Fetch<ClanWarGetAvailableFightersResponse>('/api/clan/war/fighters', { clan, war }, 'POST'),
    getUsedFighters: (clan: string, war: string) => Fetch<ClanWarGetUsedFightersResponse>('/api/clan/war/fighters/used', { clan, war }, 'POST'),
    toggleFighter: (
      clan: string,
      war: string,
      fighter: string,
      add: boolean,
    ) => Fetch<never>('/api/clan/war/fighters/toggle', {
      clan,
      war,
      fighter,
      add: add ? 'true' : 'false',
    }, 'PATCH'),
    cancel: (brute: string, clan: string, war: string) => Fetch<never>('/api/clan/war', { brute, clan, war }, 'DELETE'),
    accept: (brute: string, clan: string, war: string) => Fetch<never>('/api/clan/war/accept', { brute, clan, war }, 'PATCH'),
    getFight: (warId: string, fightId: string) => Fetch<FightGetResponse>(`/api/clan/war/${warId}/fight/${fightId}`),
  },
  Event: {
    list: (page: number) => Fetch<EventListResponse>('/api/event/list', { page }),
    get: (bruteId: string, id: string) => Fetch<EventGetResponse>(`/api/event/${id}/brute/${bruteId}`),
    listCurrent: () => Fetch<EventListCurrentResponse>('/api/event/currents'),
    getRound: (id: string, round: string) => Fetch<EventGetRoundResponse>(`/api/event/${id}/round/${round}`),
  },
  Notification: {
    list: () => Fetch<NotificationListResponse>('/api/notification/list'),
    setRead: (id: string) => Fetch<never>(`/api/notification/${id}/read`, {}, 'PATCH'),
    setAllRead: () => Fetch<never>('/api/notification/all/read', {}, 'PATCH'),
  },
  Config: {
    list: () => Fetch<ConfigsListResponse>('/api/config/list'),
    set: (key: string, value: string) => Fetch<ConfigsSetResponse>('/api/config/set', { key, value } as ConfigsSetRequest, 'PUT'),
    decrypt: (value: string) => Fetch<ConfigsDecryptResponse>('/api/config/decrypt', { value } as ConfigsDecryptRequest, 'POST'),
    delete: (key: string) => Fetch<never>('/api/config', { key }, 'DELETE'),
  },
  UserLog: {
    list: (params: UserLogsListRequest) => Fetch<UserLogsListResponse>('/api/user-log/list', params, 'POST'),
  },
};

export default Server;
